/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

const ContactUs = () => {
    const { t } = useTranslation();
    return (
        <div className="page not snap" id="contact">
            <h1 className="page_title"><span className="redText">{t('CONTACT.contact')}</span><br />{t('CONTACT.us')}</h1>
            <h2 className="responsive_header" />
            <div className="pageContent">
                <a href="#map-canvas">
                    <p id="contactKL">&gt; {t('CONTACT.kl.kl')}</p>
                </a>
                <a href="#map-canvas">
                    <p id="contactSG">&gt; {t('CONTACT.sg.sg')}</p>
                </a>
                <a href="#map-canvas">
                    <p id="contactSY">&gt; {t('CONTACT.au.au')}</p>
                </a>
                <a href="#map-canvas">
                    <p id="contactHK">&gt; {t('CONTACT.hk.hk')}</p>
                </a>
                <a href="#map-canvas">
                    <p id="contactSH">&gt; {t('CONTACT.cn.cn')}</p>
                </a>
            </div>
            <div id="map-canvas"> </div>
            <div id="mapText">
                <div id="detailsKL">
                    <p><span className="cdsb">CDSB</span></p>
                    <h3>{t('CONTACT.kl.cdsb')}</h3>
                    <p>16.01A, 16th Floor, Plaza First Nationwide<br />
                        No. 161, Jalan Tun H. S. Lee<br />
                        50000, Kuala Lumpur, Malaysia
                    </p>
                    <p><b>{t('CONTACT.telephone')}</b> (603) 2070 2104/05</p>
                    <p><b>{t('CONTACT.fax')}</b> (603) 2070 2103</p>
                    <p><b>{t('CONTACT.email')}</b> cdsb@icapital.biz</p>
                    <p><b>{t('CONTACT.website')}</b><a href="https://www.icapital.biz/" target="_blank" rel="noreferrer">www.icapital.biz</a></p>
                    <br />
                    <p><span className="cdam">CDAM</span></p>
                    <h3>{t('CONTACT.kl.cdam')}</h3>
                    <p>16.01B, 16th Floor, Plaza First Nationwide<br />
                        No. 161, Jalan Tun H. S. Lee<br />
                        50000, Kuala Lumpur, Malaysia
                    </p>
                    <p><b>{t('CONTACT.telephone')}</b> (603) 2070 2106/07/08</p>
                    <p><b>{t('CONTACT.fax')}</b> 03-2070 6653</p>
                    <p><b>{t('CONTACT.email')}</b> cdam@cdam.biz</p>
                    <p><b>{t('CONTACT.website')}: </b><a href="https://www.cdam.biz/" target="_blank" rel="noreferrer">www.cdam.biz</a></p>
                </div>
                <DetailsBox id="detailsSG" company={t('CONTACT.sg.name')} phone="(65) 62248055/56/58" email="cdpl@icapital.biz" url="capitaldynamics.com.sg" >
                    <p>
                        <Trans i18nKey="CONTACT.sg.add"></Trans>
                    </p>
                </DetailsBox>
                <DetailsBox id="detailsSY" company={t('CONTACT.au.name')} phone="(612) 9262 2621/16/36" fax="(612) 9262 2729" email="info@capitaldynamics.com.au" url="capitaldynamics.com.au" >
                    <p>
                        <Trans i18nKey="CONTACT.au.add"></Trans>
                    </p>
                </DetailsBox>
                <DetailsBox id="detailsHK" company={t('CONTACT.hk.name')} phone="(852) 2153 1455" fax="248057" email="cdhk@capitaldynamics.hk" url="capitaldynamics.hk" >
                    <p>
                        <Trans i18nKey="CONTACT.hk.add"></Trans>
                    </p>
                </DetailsBox>
                <DetailsBox id="detailsSH" company={t('CONTACT.cn.name')} phone="+86 21 5308 5066" email="enquiries@capitaldynamics.cn.com" url="capitaldynamics.cn.com" >
                    <p>
                        <Trans i18nKey="CONTACT.cn.add"></Trans>
                    </p>
                </DetailsBox>
            </div>
        </div>
    );
};

export default ContactUs;

const DetailsBox = ({ id, company, children, phone, fax, email, url }) => {
    const { t } = useTranslation();
    return (
        <div id={id}>
            <h3>{company}</h3>
            {children}
            <p><b>{t('CONTACT.telephone')}</b> {phone}</p>
            {typeof fax === "undefined" ? '' : <p><b>{t('CONTACT.fax')}</b> {fax}</p>}
            <p><b>{t('CONTACT.email')}</b> {email}</p>
            <p><b>{t('CONTACT.website')}</b><a href={`https://www.${url}/`} target="_blank" rel="noreferrer">www.{url}</a>
            </p>
        </div>
    );
};
